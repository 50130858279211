import { createTheme, ThemeProvider } from "@mui/material";
import { HashRouter, useRoutes, useLocation, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import TvHandler from "./components/TvHandler";
import StoreObserver from "./firebase/StoreObserver";
import Idle from "./screen/Idle";
import ImageViewer from "./screen/ImageViewer";
import LauncherMessage from "./screen/LauncherMessage";
import Popup from "./screen/Popup";
import SliderMessage from "./screen/SliderMessage";
import TextMessage from "./screen/TextMessage";
import VideoMessage from "./screen/VideoMessage";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontSize: 32,
  },
});

let cnt = 0;

function App() {
  const url = new URL(window.location.href);
  const queryString = new URLSearchParams(url.search);
  const msg = queryString.get('msg');

  console.log('fuck msg :' + msg);
  return (
    <RecoilRoot>
      <ThemeProvider theme={darkTheme}>
        <HashRouter>
          <StoreObserver />
          <TvHandler />
          <AppRoutes />
          {/* <Route path="/" component={MyComponent} /> */}
        </HashRouter>
      </ThemeProvider>
    </RecoilRoot>
  );
}

const AppRoutes = () => {
  const routes = useRoutes([
    { path: "/", element: <Idle /> },
    { path: "/demo", element: <Popup key={cnt++} /> },
    { path: "/image", element: <TextMessage image key={cnt++} /> },
    { path: "/stdemo", element: <TextMessage stdemo key={cnt++} /> },
    { path: "/homeytalk", element: <TextMessage homey key={cnt++} /> },
    { path: "/slider", element: <TextMessage slider key={cnt++} /> },
    {
      path: "/youtubetalk",
      element: <TextMessage youtube key={cnt++} />,
    },
    {
      path: "/youtube",
      element: <LauncherMessage type={"youtube"} key={cnt++} />,
    },
    { path: "/kakaotalk", element: <TextMessage kakaotalk key={cnt++} /> },
    { path: "/detail", element: <SliderMessage key={cnt++} /> },
    { path: "/viewer", element: <ImageViewer /> },
    {
      path: "/shopping",
      element: <LauncherMessage type={"browser"} key={cnt++} />,
    },
    {
      path: "/netflix",
      element: <LauncherMessage type={"netflix"} key={cnt++} />,
    },
    { path: "/drawmemo", element: <SliderMessage memo key={cnt++} /> },
    { path: "/video", element: <VideoMessage memo key={cnt++} /> },
  ]);
  return routes;
};

export default App;
