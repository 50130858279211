import { Avatar, CardHeader, Chip, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useSearchParams, useParams } from "react-router-dom";
import ariana from "../assets/images/ariana_avatar.png";
import stlogo from "../assets/images/st-logo-b-d4801db2.png"
import homeylogo from "../assets/images/homey@2x.webp"
import kakaoIcon from "../assets/images/ic_kakao_talk.png";
import thinqIcon from "../assets/images/ic_launcher_thinq.png";
import TTSGoogleNode from "../components/TTSGoogleNode";
import { debug } from "../data/constants";
import { db } from "../firebase/firebase";
import VideoPlayer2 from '../components/videoPlayer2';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CallEndIcon from '@mui/icons-material/CallEnd';
import DoorbellIcon from '@mui/icons-material/Doorbell';
// import CameraIndoorIcon from '@mui/icons-material/CameraIndoor';

import CameraOutdoorIcon from '@mui/icons-material/CameraOutdoor';

import './VideoMessage.css';

import xImage from '../assets/images/x@2x.webp';
import doorBellImage from '../assets/images/doorbell@2x.webp';

import { logState } from "../data/logState";
import { useRecoilState, useResetRecoilState } from "recoil";

import queryString from 'query-string';

function VideoMessage(props) {
  const { stdemo, image, slider, youtube, kakaotalk, homey } = props;
  const [event, setEvent] = useState("null");
  const [icon, setIcon] = useState(thinqIcon);
  const [from, setFrom] = useState(null);
  const [message, setMessage] = useState(null);
  const [ttsText, setTtsText] = useState();
  const [mediaReady, setMediaReady] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [log, setLog]= useRecoilState(logState);
  const [hlsUrl, setHlsUrl] = useState(null);

  const [testSrc, setTestSrc] = useState(null);
  
  const url = new URL(window.location.href);
  const queryString = new URLSearchParams(url.search);
  let msg = queryString.get('msg');

  if (msg != null) {
    msg = decodeURI(msg);
  }

  console.log('fuck msg :' + msg);

  const openRef = useRef();
  const endRef = useRef();

  const addLog = (newLog) => {
    setLog((oldLog) => [...oldLog, newLog]);
  };

  window.updateTest = function(newSrc) {
    setTestSrc(newSrc);
  }

  useEffect(() => {
    if (testSrc) {
      setMediaReady(true);
      setHlsUrl(testSrc);
      addLog("VideoMessage hls url : ", testSrc);
      addLog("Test log...");
    }
  }, [testSrc]);

  useEffect(() => {
    window.connectManager.on("message", function (data) {
      console.log("videoMessage onMessage from client", data);
      var message = data.message;

      if (typeof message == "object") {
        if (message?.type === "hlsStart") {
          setMediaReady(true);
          setHlsUrl(message.url)
          addLog("VideoMessage hls url : " + message.url);
          addLog("Test log...");
        }
      }
    });
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // 키보드 이벤트 처리
      if (e.key === 'ArrowRight') {
        handleButton(e);
      } else if (e.key === 'ArrowLeft') {
        // 왼쪽 화살표 키가 눌렸을 때 처리
        // 페이지 이동 등
        handleButton(e);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (openRef != null) {
      openRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (homey) {
      setIcon(homeylogo);
      setFrom("Message");
      setMessage(msg ||"defaut msg");
    } else if (stdemo) {
      setIcon(stlogo);
      setFrom("Camera sensor");
      setMessage("AXIS M1065-LW\nPerson recognized.");
    } else if (image) {
      setIcon(ariana);
      setFrom("사랑하는 우리 딸");
      setMessage("fuck 아빠!\n지난 주말에는 미안했어~\n사랑해!");
    } else if (slider) {
      setIcon(ariana);
      setFrom("사랑하는 우리 딸");
      setMessage("fuck 엄마!\n지난겨울에 애들과 스키장 다녀온 사진이예요");
    } else if (youtube) {
      const getContents = async () => {
        const docSnap = await getDoc(doc(db, "thinq_talk", "contents"));
        setIcon(ariana);
        setFrom("fuck 사랑하는 우리 딸");
        setMessage(docSnap.data().text);
      };
      getContents();
    } else if (kakaotalk) {
      const getContents = async () => {
        const docSnap = await getDoc(doc(db, "thinq_talk", "contents"));
        setIcon(kakaoIcon);
        setFrom(docSnap.data().from);
        setMessage(docSnap.data().text);
      };
      getContents();
    }
  }, [image, kakaotalk, slider, youtube, stdemo]);

  useEffect(() => {
    if (message !== null) {
      setTtsText({ from: from, message: message });
    }
  }, [from, message]);

  const keyListener = useCallback(
    (e) => {
      setEvent(e.key);
      if (e.key === "Enter") {
        if (image || slider) {
          localStorage.setItem("imgIndex", 0);
          navigate("/detail", {
            replace: true,
            state: { keyevent: e.key, from: from, message: message, slider },
          });
        } else if (youtube) {
          setDoc(doc(db, "thinq_talk", "youtube"), {
            play: true,
          });
          setTimeout(() => {
            navigate("/", { replace: true });
            setDoc(doc(db, "thinq_talk", "application"), {
              poweron: false,
            });
          }, 500);
        } else if (kakaotalk) {
          navigate("/", { replace: true });
          setDoc(doc(db, "thinq_talk", "application"), {
            poweron: false,
          });
        } else if (stdemo) {
          navigate("/", { replace: true });
          setDoc(doc(db, "thinq_talk", "application"), {
            poweron: false,
          });
        }
      } else if (e.key === "GoBack") {
        // need finish web app
        navigate("/", { replace: true });
        setDoc(doc(db, "thinq_talk", "application"), {
          poweron: false,
        });
      }
    },
    [navigate, image, slider, youtube, kakaotalk, from, message, stdemo]
  );

  const clickListener = useCallback(
    (e) => {
      keyListener({ key: "Enter" });
    },
    [keyListener]
  );

  const handleButton = ((event) => {
    if (openRef.current.contains(event.target)) {
      endRef.current.focus();
    } else {
      openRef.current.focus();
    }
  });

  useEffect(() => {
    window.addEventListener("keydown", keyListener);
    window.addEventListener("click", clickListener);
    return () => {
      window.removeEventListener("keydown", keyListener);
      window.removeEventListener("click", clickListener);
    };
  }, [clickListener, keyListener]);

  return (
    <>
      <div>
        <h1 className="my_log">
          {log.map((item, index) => (
            <React.Fragment key={index}>
              {item}
              <br />
            </React.Fragment>
          ))}
        </h1>
      </div>
      <div className="Frame-4">
        <div className="Rectangle-1">
        {mediaReady ? 
        (
          <VideoPlayer2 src={hlsUrl} type="m3u8"/>
        ) 
        : 
        (
          <div className="hls-video" style={{backgroundColor: 'gray', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {/* <img src="your_image_file_path" alt="description" /> */}
            <CameraOutdoorIcon/>
          </div>
        )}
        </div>
        <div className="Frame-6">
            <img src={doorBellImage}
            class="img-Frame-6"/>
        </div>
        <div className="camera-controls">
          <button className="Frame-1" ref={openRef} onClick={() => {
            const message = {
              door: {
                type: 'open',
              },
            };
            window.connectManager.sendMessage(localStorage.getItem("cliendId"), message);
            console.log('kbk open clicked');
          }}>
            <span class="Door-Open">
              Door Open
            </span>
          </button>
          <button className="Frame-2" ref={endRef} onClick={() => {
            const message = {
              door: {
                type: 'end',
              },
            };
            window.connectManager.sendMessage(localStorage.getItem("cliendId"), message);
            console.log('kbk end clicked');
            // window.close();
          }}>
            <img src={xImage}
              class="Frame-5"/>
          </button>
        </div>
      </div>
    </>
  );
}

export default VideoMessage;
