import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '../screen/VideoMessage.css';
import { logState } from "../data/logState";
import { useRecoilState, useResetRecoilState } from "recoil";

const VideoPlayer2 = ({ src, options, onReady }) => {
  const videoRef = useRef();
  const [log, setLog]= useRecoilState(logState);

  const addLog = (newLog) => {
    setLog((oldLog) => [...oldLog, newLog]);
  };

  const dualRecOptions = {
    htmlMediaOption: {
      useUMSMediaInfo: true,
      useDedicatedResource: true,
    },
    option: {
      avSink: {
        audioSink: {
        },
        videoSink: {
          "type" : "sub_video"
        }
      }
    }
  };

  useEffect(() => {
    //hls
    if (src) {
      const source = document.createElement("source");
      source.setAttribute('id', 'sample_video');
      source.setAttribute('src', src);
      var mediaOption = encodeURI(JSON.stringify(dualRecOptions));
      source.setAttribute('type', `application/x-mpegURL;mediaOption=${mediaOption}`);
      videoRef.current.appendChild(source);
      addLog('videoPlayer2.js src : ' + src);
    }
  }, [src]);


  useEffect(() => {
    //mp4
    // const source = document.createElement("source");
    // source.setAttribute('id', 'sample_video');
    // source.setAttribute('src', 'jang.mp4');
    // var mediaOption = encodeURI(JSON.stringify(dualRecOptions));
    // source.setAttribute('type', `video/mp4;mediaOption=${mediaOption}`);
    // videoRef.current.appendChild(source);

    //hls
    // const source = document.createElement("source");
    // source.setAttribute('id', 'sample_video');
    // console.log('fuck src : ', src);
    // source.setAttribute('src', src || 'http://bonkab.com:49153/stream/48c10bd4-7339-4ea2-81ba-f15844e659a7/index.m3u8');
    // var mediaOption = encodeURI(JSON.stringify(dualRecOptions));
    // source.setAttribute('type', `application/x-mpegURL;mediaOption=${mediaOption}`);
    // videoRef.current.appendChild(source);
  }, []);

  const mediaOptions = `application/x-mpegURL;mediaOption=${escape(JSON.stringify(dualRecOptions))}`;
  // const mediaOptions = `video/mp4;mediaOption=${escape(JSON.stringify(dualRecOptions))}`;

  useEffect(() => {
    const videoElement = videoRef.current;
    let player;
    if (videoElement) {
      player = videojs(videoElement, options, () => {
        console.log('Player is ready');
        onReady && onReady(player);
      });
    }

    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [options, onReady]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered hls-video" preload="auto" autoPlay muted style={{ position: 'absolute'}}>
        {/* <source src="http://bonkab.com:49153/stream/48c10bd4-7339-4ea2-81ba-f15844e659a7/index.m3u8" type={mediaOptions} /> */}
        {/* <source type={mediaOptions} /> */}
      </video>
    </div>
  );
};

export default VideoPlayer2;