import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState, useRef } from "react";
import { db } from "../firebase/firebase";
import { useNavigate, useLocation } from "react-router-dom";

const useNavigation = () => {
  const nativeNavigate = useNavigate();
  const navigate = (destination, options) => {
    nativeNavigate(destination, options);
  };

  return { navigate };
};
function Idle(props) {
  const [state, setState] = useState(".");
  const navigation = useRef(useNavigation());

  useEffect(() => {
    const msgTypeDocRef = doc(db, "thinq_talk", "message_type");
    setTimeout(async () => {
      const msgTypeSnap = await getDoc(msgTypeDocRef);
      if (!msgTypeSnap.exists || msgTypeSnap.data().type === "") {
        return;
      }
      setState(".");
      setDoc(msgTypeDocRef, { type: "" }).then(() => {
        const stored = msgTypeSnap.data().type;
        setDoc(msgTypeDocRef, { type: stored });
      });
    }, 5000);

    navigation.current.navigate("/video");
  }, []);
  return (
    <Box sx={{ position: "absolute", top: 0, left: 4 }}>
      <Typography variant="h1" component="div" color="red">
        {state}
      </Typography>
    </Box>
  );
}

export default Idle;
